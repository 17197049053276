import React from 'react';
import './index.css';
import 'leaflet/dist/leaflet.css';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, Link, Outlet, redirect, RouterProvider, useMatch } from 'react-router-dom';
import Home, { loader as homeLoader } from './pages/home';
import ErrorPage from './components/error-page';
import {
  Button,
  colors,
  Container,
  createTheme,
  Divider,
  Hidden,
  MenuItem,
  Select,
  Stack,
  ThemeProvider,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from '@mui/material';
import About from './pages/about/about';
import Indicators from './pages/indicators';
import Activities from './pages/activities';
import StudySites from './pages/study-sites/study-sites';
import ProjectTeam from './pages/project-team';
import Publication from './pages/publication/publication';
import ComingSoon from './components/coming-soon';
import { Call, Email, FormatAlignCenter, Telegram, Web } from '@mui/icons-material';
import SupportedBy from './components/supported-by';
import Berita from './pages/berita/berita';
import Cookies from 'js-cookie';
import lang from './helpers/translate';

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = createTheme({
  typography: {
    fontFamily: ['Poppins'],
  },
  palette: {
    primary: {
      main: '#516D4D',
    },
    secondary: {
      main: colors.amber[400],
    },
  },
});

const navMenus = [
  {
    key: 'home',
    title: lang('home'),
    slug: '/',
  },
  {
    key: 'about',
    title: lang('about'),
    slug: '/about',
  },
  {
    key: 'activities',
    title: lang('activity'),
    slug: '/activities',
  },
  {
    key: 'data',
    title: lang('data'),
    slug: '/study-sites',
  },
  {
    key: 'project-team',
    title: lang('team'),
    slug: '/project-team',
  },
  {
    key: 'article',
    title: lang('reports'),
    slug: '/article',
  },
  {
    key: 'berita',
    title: lang('news'),
    slug: '/berita',
  },
];

const NavbarItem = (item) => {
  const match = useMatch(item.slug);
  return (
    <Link key={item.key} to={item.slug} style={{ textDecoration: 'none' }}>
      <Button size='small' variant='outlined' sx={{ color: 'white', borderColor: 'white' }}>
        {item.title}
      </Button>
    </Link>
  );
};

const ToggleLang = () => {
  const changeLang = (key) => {
    Cookies.set('lang', key);
    window.location.reload();
  };
  return (
    <Stack px={1} pb={1}>
      <ToggleButtonGroup value={Cookies.get('lang') || 'id'} exclusive aria-label='text alignment'>
        <ToggleButton value='id' aria-label='centered' onClick={() => changeLang('id')}>
          <span class='fi fi-id'></span>
        </ToggleButton>
        <ToggleButton value='gb' aria-label='centered' onClick={() => changeLang('gb')}>
          <span class='fi fi-gb'></span>
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
};

const Navbar = () => {
  const matchSm = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Stack direction='column' sx={{ backgroundColor: '#516D4D' }}>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Stack direction='column' p={6} spacing={2}>
          <Stack direction='column' spacing={1}>
            <Typography sx={{ color: 'whitesmoke', fontWeight: 700, textAlign: 'left' }} variant={matchSm ? 'h4' : 'h6'}>
              {lang('title')}
            </Typography>
            <Typography sx={{ color: 'whitesmoke', fontWeight: 400, textAlign: 'left' }} variant={matchSm ? 'body1' : 'h6'}>
              {lang('subtitle')}
            </Typography>
          </Stack>
          <Stack spacing={{ xs: 1, md: 2 }} direction={{ xs: 'column', md: 'row' }}>
            {navMenus.map((item) => NavbarItem(item))}
          </Stack>
        </Stack>
        <Hidden mdDown>
          <Stack direction='column' sx={{ alignItems: 'center' }}>
            <img src='./assets/webprofile/logo.png' style={{ width: 200 }} />
            <ToggleLang />
          </Stack>
        </Hidden>
      </Stack>
      <Hidden mdUp>
        <Stack direction='column' sx={{ alignItems: 'center' }}>
          <ToggleLang />
        </Stack>
      </Hidden>
    </Stack>
  );
};

const Footer = () => {
  const matchSm = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Stack direction='column'>
      {/* <Divider /> */}
      <SupportedBy />
      <Stack direction='column' alignItems='center' sx={{ height: 'auto', backgroundColor: '#516D4D' }} spacing={2}>
        <Stack direction={{ xs: 'column', lg: 'row' }}>
          <Stack flex={3}>
            <img src='./assets/footer/footer_1.png' style={{ width: '100%' }} />
          </Stack>

          <Stack flex={1} direction='column' justifyContent='space-between' p={6}>
            <Typography variant='h4' sx={{ color: 'white' }}>
              INHES
            </Typography>
            <Stack direction='column' spacing={1}>
              <Stack direction='row' alignItems='center' spacing={1}>
                <Call sx={{ color: 'white' }} />
                <Typography variant='body2' sx={{ color: 'white' }}>
                  +6282210274526
                </Typography>
              </Stack>
              <Stack direction='row' alignItems='center' spacing={1}>
                <Email sx={{ color: 'white' }} />
                <Typography variant='body2' sx={{ color: 'white' }}>
                  inhes.study@gmail.com
                </Typography>
              </Stack>
              <Stack direction='row' alignItems='center' spacing={1}>
                <Telegram sx={{ color: 'white' }} />
                <Typography variant='body2' sx={{ color: 'white' }}>
                  https://inhes.org
                </Typography>
              </Stack>
              <Stack direction='row' alignItems='center' spacing={1}>
                <Typography variant='body2' sx={{ color: 'white' }}>
                  &copy; 2024
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const Header = () => {
  return (
    <Stack sx={{ backgroundColor: '#F5F5F5' }}>
      <Navbar />
      <Outlet />
      <Footer />
    </Stack>
  );
};

const router = createBrowserRouter([
  {
    element: <Header />,
    children: [
      {
        path: '/',
        element: <Home />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/home',
        element: <Home />,
        errorElement: <ErrorPage />,
        loader: homeLoader,
      },
      {
        path: '/about',
        element: <About />,
        errorElement: <ErrorPage />,
        // loader: homeLoader,
      },
      {
        path: '/adolescent-indicators',
        element: <Indicators />,
        errorElement: <ErrorPage />,
        // loader: homeLoader,
      },

      {
        path: '/activities',
        element: <Activities />,
        errorElement: <ErrorPage />,
        // loader: homeLoader,
      },
      {
        path: '/study-sites',
        element: <StudySites />,
        errorElement: <ErrorPage />,
        // loader: homeLoader,
      },
      {
        path: '/project-team',
        element: <ProjectTeam />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/article',
        element: <Publication />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/berita',
        element: <Berita theme={theme} />,
        errorElement: <ErrorPage />,
      },
    ],
  },
]);

root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <RouterProvider router={router} />
  </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
