import _ from 'lodash';
import langs from '../data/langs.json';
import Cookies from 'js-cookie';

export default function lang(key) {
  const lang = Cookies.get('lang') || 'id';
  const _index = _.findIndex(langs, { key: key });
  if (_index < 0) return key;
  if (lang === 'id') return langs[_index]?.lang_id;
  if (lang === 'gb') return langs[_index]?.lang_en;
  return langs[_index]?.lang_id;
}
