import { Button, Container, Divider, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import React from 'react';
import { MapContainer, Marker, Popup, TileLayer, Tooltip } from 'react-leaflet';
import _ from 'underscore';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import map from 'highcharts/modules/map';
import TiledWebMap from 'highcharts/modules/tiledwebmap';
import exporting from 'highcharts/modules/exporting';
import offlineExporting from 'highcharts/modules/offline-exporting';
import accessibility from 'highcharts/modules/accessibility';
import world from '@highcharts/map-collection/custom/world.geo.json';
import country from '@highcharts/map-collection/countries/id/id-all.topo.json';

import gktopojson from '../gk.topo.json';
import sourcejson from '../../data/source.json';
import sourcejson_en from '../../data/source_en.json';
import { CloseOutlined } from '@mui/icons-material';
import IndicatorDetailDialog from './indicator-detail-dialog';
import IndicatorsInfo from './indicators-info';
import lang from '../../helpers/translate';

import Cookies from 'js-cookie';

map(Highcharts);
TiledWebMap(Highcharts);
exporting(Highcharts);
offlineExporting(Highcharts);
accessibility(Highcharts);

const StudySites = () => {
  const [districts, setDistricts] = React.useState();

  const [indicators, setIndicators] = React.useState([]);
  const [indicatorsInfo, setIndicatorsInfo] = React.useState();
  const [selectedIndicators, setSelectedIndicators] = React.useState({});

  const [chartOptions, setChartOptions] = React.useState({});

  const [dialog, setDialog] = React.useState({});

  const [filter, setFilter] = React.useState({
    district: 'Gunung Kidul',
    subdistrict: '',
    village: '',
    category: lang('age'),
    sub_category: '10-15',
    gender: lang('all'),
  });

  React.useEffect(() => {
    prepareMapData();
    grepDisctrictAndSubDistrict();
    grepCategories();
  }, []);

  React.useEffect(() => {
    prepareMapData();
    setSelectedIndicators({ ...selectedIndicators, category: filter.category, sub_category: filter.sub_category, gender: filter.gender });
  }, [filter]);

  const prepareMapData = () => {
    console.log('prepare map');
    const _geo = gktopojson;
    const _source = Cookies.get('lang') === 'gb' ? sourcejson_en : sourcejson;
    const _geometries = _geo.objects['data'].geometries;

    const _groupByVillage = _.groupBy(_source, 'desa');

    // const findIndex = (value) => { _.findIndex(_groupByVillage, value)}

    _geometries.forEach((item, index) => {
      const desa = item?.properties?.desa;
      if (desa) {
        const _metadata = _groupByVillage[desa.toUpperCase()] || null;
        let _indicator_value = 0;
        if (_metadata)
          _metadata.forEach((meta, metaIndex) => {
            if (meta?.category === filter.category && meta?.sub_category === filter.sub_category && meta?.gender === filter.gender)
              _indicator_value = meta.percent;
          });

        _geometries[index]['properties'] = {
          ..._geometries[index]['properties'],
          metadata: _metadata,
          indicator_value: _indicator_value,
        };
      }
    });
    _geo.objects['data'].geometries = _geometries;
    grepObjectData(_geo);
  };

  const grepObjectData = (geo) => {
    const _objectData = [];
    const _geometries = geo?.objects['data'].geometries;

    if (_.isEmpty(_geometries)) return;

    _geometries.forEach((item, index) => {
      _objectData.push(item?.properties?.indicator_value || 0);
    });

    setChartOptions({
      title: {
        text: '',
      },
      chart: {
        map: geo,
        height: 600,
      },
      mapNavigation: {
        enabled: true,
      },

      mapView: {
        insetOptions: {
          borderColor: 'silver',
          borderWidth: 2,
        },
      },

      colorAxis: {
        min: 0,
        max: 100,
        tickPixelInterval: 100,
        minColor: '#F5F5F5',
        maxColor: '#0D47A1',
      },

      series: [
        {
          type: 'tiledwebmap',
          name: 'Basemap Tiles',
          provider: {
            type: 'Esri',
            theme: 'WorldGrayCanvas',
            subdomain: 'c',
          },
          showInLegend: false,
        },
        {
          point: {
            events: {
              click: function () {
                if (this.properties.indicator_value) setIndicatorsInfo(this.properties);

                // setDialog({ ...dialog, open: true, metadata: this.properties });
              },
            },
          },
          data: _objectData,
          joinBy: null,
          name: 'Indicator name',
          dataLabels: {
            enabled: false,
            formatter: function () {
              // Check if the value is zero
              if (this.point.properties.indicator_value !== 0) {
                return this.point.properties.indicator_value;
              } else {
                // Return empty string if value is zero
                return '';
              }
            },
          },
          states: {
            hover: {
              color: '#ffeb3b',
            },
          },
        },
      ],
      tooltip: {
        formatter: function () {
          return this.point.properties.indicator_value > 0
            ? `<p><b>${this.point.properties.desa}</b></p></br><p>${JSON.stringify(
                this.point.properties.indicator_value
              )}</p></br><i>Click to show more data</i>`
            : `<p><b>${this.point.properties.desa}</b></p>`;
        },
      },
    });
  };

  const MyChart = ({ chartOptions }) => {
    const chartRef = React.useRef(null);

    React.useEffect(() => {
      // Access the chart instance using chartRef.current
      const chart = chartRef.current.chart;

      // Update chart options or perform any other modifications
      // ...

      // Redraw the chart
      chart.redraw();
    }, [chartOptions]);

    return <HighchartsReact constructorType={'mapChart'} highcharts={Highcharts} options={chartOptions} ref={chartRef} />;
  };

  const grepDisctrictAndSubDistrict = () => {
    const _data = gktopojson.objects['data'].geometries;
    const _subdistricts = [];
    const _fulldata = [];

    _data.forEach((item) => {
      if (!_subdistricts.includes(item.properties.kecamatan) && item.properties.kecamatan) _subdistricts.push(item.properties.kecamatan);
    });

    _data.forEach((item) => {
      if (item.properties.kecamatan)
        _fulldata.push({
          district: 'GUNUNG KIDUL',
          subdistrict: item.properties.kecamatan.toUpperCase(),
          village: item.properties.desa.toUpperCase(),
        });
    });

    // const _grouped = _.groupBy(_fulldata, 'subdistrict');
    setDistricts(_fulldata);
  };

  const clearFilter = () => {
    setFilter({});
    setSelectedIndicators({});
    setIndicatorsInfo({});
  };

  const grepCategories = () => {
    const _data = Cookies.get('lang') === 'gb' ? sourcejson_en : sourcejson;
    const _categories = _.uniq(_data, (item) => item.sub_category);
    const _gender = _.uniq(_data, (item) => item.gender);

    const _filtered_gender = [];

    _gender.forEach((item) => _filtered_gender.push(item.gender));

    const _filtered = [];

    _categories.forEach((item) => {
      _filtered.push({ category: item.category, sub_category: item.sub_category, gender: _filtered_gender });
    });

    const _grouped = _.groupBy(_filtered, 'category');

    const _indicators = [];

    Object.keys(_grouped).forEach((key) => _indicators.push({ id: key, label: key, subindicators: _grouped[key] }));

    setIndicators(_indicators);
  };

  return (
    <Container maxWidth='xl'>
      <Stack direction='column' spacing={2} p={3}>
        {/* <Typography variant='h5' sx={{ fontWeight: 700 }}>
          Study Sites
        </Typography> */}

        <Stack direction='column'>
          <Typography variant='h6' sx={{ fontWeight: 700 }}>
            {lang('data')}
          </Typography>

          <Typography variant='caption'>{lang('select_indicator')}</Typography>
        </Stack>

        <Stack direction={{ lg: 'row' }} spacing={6} sx={{ flex: 1 }} justifyContent='space-between'>
          {/* <Stack sx={{ flex: 1 }}>
          {indicators.map((indicator) => (
            <Stack direction='column' spacing={1} key={indicator.id} mb={2}>
              <Typography sx={{ fontWeight: 700 }}>{indicator.label}</Typography>
              {indicator?.subindicators?.map((sub, i) => (
                <Stack
                  key={i}
                  direction='column'
                  spacing={1}
                  style={{ marginLeft: 16 }}
                  onClick={() => {
                    setSelectedIndicators({ ...selectedIndicators, category: indicator.id, sub_category: sub.sub_category });
                    setFilter({ ...filter, category: indicator.label, sub_category: sub.sub_category, gender: 'Overall' });
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  <Typography>{sub.sub_category}</Typography>
                </Stack>
              ))}
            </Stack>
          ))}
        </Stack> */}
          <Stack sx={{ flex: 8 }}>
            <Stack direction='column' spacing={2}>
              <Stack direction='row'>
                <Button onClick={() => clearFilter()} variant='outlined' size='small' startIcon={<CloseOutlined />}>
                  {lang('clear_filter')}
                </Button>
              </Stack>
              {/* LOCALIZATION */}
              {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <FormControl fullWidth size='small'>
                <InputLabel id='district'>District</InputLabel>
                <Select
                  labelId='district'
                  id='district'
                  value='GUNUNG KIDUL'
                  label='District'
                  onChange={(e) => setFilter({ ...filter, district: e.target.value, subdistrict: '' })}
                >
                  <MenuItem value='GUNUNG KIDUL'>GUNUNG KIDUL</MenuItem>
                </Select>
              </FormControl>
              {!_.isEmpty(districts) && (
                <>
                  <FormControl fullWidth size='small'>
                    <InputLabel id='subdistrict'>Sub-district</InputLabel>
                    <Select
                      labelId='subdistrict'
                      id='subdistrict'
                      value={filter.subdistrict}
                      label='Sub-district'
                      onChange={(e) => setFilter({ ...filter, subdistrict: e.target.value, village: '' })}
                    >
                      {_.uniq(districts, (obj) => obj.subdistrict)
                        .filter((ds) => ds.district === 'GUNUNG KIDUL')
                        .map((item, index) => (
                          <MenuItem key={index} value={item.subdistrict}>
                            {item.subdistrict}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth size='small'>
                    <InputLabel id='village'>Village</InputLabel>
                    <Select
                      labelId='village'
                      id='village'
                      value={filter.village}
                      label='Village'
                      onChange={(e) => setFilter({ ...filter, village: e.target.value })}
                    >
                      {districts
                        .filter((item) => item.subdistrict === filter.subdistrict)
                        .map((item, index) => (
                          <MenuItem key={index} value={item.village}>
                            {item.village}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </>
              )}
            </Stack> */}
              {/* CATEGORY FILTERS */}
              {/* {JSON.stringify(filter)} */}
              {/* {JSON.stringify(_.findIndex(indicators, 'BMI'))} */}
              {/* {JSON.stringify(_.groupBy(indicators, 'id'))} */}
              {/* {JSON.stringify(_.groupBy(indicators, 'id')[filter.category][0].subindicators)} */}
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <FormControl fullWidth size='small'>
                  <InputLabel id='category'>{lang('category')}</InputLabel>
                  <Select
                    labelId='category'
                    id='category'
                    value={filter.category || ''}
                    label={lang('category')}
                    onChange={(e) => {
                      setFilter({ ...filter, category: e.target.value, sub_category: null, gender: null });
                      setIndicatorsInfo({});
                    }}
                  >
                    {indicators.map((item, index) => (
                      <MenuItem key={index} value={item.label}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth size='small'>
                  <InputLabel id='category'>{lang('subcategory')}</InputLabel>
                  <Select
                    labelId='subcategory'
                    id='subcategory'
                    value={filter.sub_category || ''}
                    label={lang('subcategory')}
                    onChange={(e) => {
                      setFilter({ ...filter, sub_category: e.target.value, gender: lang('all') });
                      setIndicatorsInfo({});
                    }}
                  >
                    {filter?.category &&
                      !_.isEmpty(indicators) &&
                      _.uniq(_.groupBy(indicators, 'id')[filter.category][0]?.subindicators, 'sub_category').map((item, index) => (
                        <MenuItem key={index} value={item.sub_category}>
                          {item.sub_category}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth size='small'>
                  <InputLabel id='category'>{lang('gender')}</InputLabel>
                  <Select
                    labelId='gender'
                    id='gender'
                    value={filter.gender || ''}
                    label={lang('gender')}
                    onChange={(e) => {
                      setFilter({ ...filter, gender: e.target.value });
                    }}
                  >
                    {_.uniq(indicators, 'gender')[0]?.subindicators[0]?.gender?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              {/* <Stack direction='column'>
              <Typography variant='body2'>
                Selected indicators:{' '}
                <b>
                  {selectedIndicators.category} - {selectedIndicators.sub_category} - {selectedIndicators.gender}
                </b>
              </Typography>
            </Stack> */}
              <Stack>
                {/* {!_.isEmpty(chartOptions) && filter.category && filter.sub_category && filter.gender && (
                  <MyChart chartOptions={chartOptions} />
                )} */}

                <MyChart chartOptions={chartOptions} />

                {/* THIS WAS USING LEAFLET */}
                {/* <MapContainer style={{ height: 700, width: '100%' }} center={[-8.01615, 110.5618481]} zoom={11} scrollWheelZoom={false}>
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                />
                {districts
                  .filter((item) => item.village === filter.village)
                  .map((item, index) => (
                    <Marker key={index} position={item.coordinate}>
                      <Popup>
                        {item.subdistrict} {item.village}
                      </Popup>
                      <Tooltip direction='bottom' offset={[0, 20]} opacity={1} permanent>
                        <Typography variant='caption' sx={{ fontWeight: 700 }}>
                          {item.subdistrict} | {item.village}
                        </Typography>
                        {subindicatorsOnly.map((item, index) => (
                          <Stack direction='column' spacing={1} key={index}>
                            <Typography variant='caption'>
                              {item.label} <b>{Math.floor(Math.random() * 100)}%</b>
                            </Typography>
                          </Stack>
                        ))}
                      </Tooltip>
                    </Marker>
                  ))}
              </MapContainer> */}
              </Stack>
            </Stack>
          </Stack>
          <Stack sx={{ flex: 4 }}>
            <IndicatorsInfo metadata={indicatorsInfo} filter={filter} />
          </Stack>
        </Stack>

        {/* <IndicatorDetailDialog dialog={dialog} setDialog={setDialog} /> */}
      </Stack>
    </Container>
  );
};

export default StudySites;
