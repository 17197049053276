import { Container, Stack, Typography } from '@mui/material';
import React from 'react';

const Indicators = () => {
  return (
    <Stack p={{ xs: 0, md: 2 }} direction='column' spacing={2}>
      <Typography variant='h5' sx={{ fontWeight: 700 }}>
        Adolescent Indicators
      </Typography>
      <Typography>Under development</Typography>
    </Stack>
  );
};

export default Indicators;
