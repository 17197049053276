import { Container, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import SlideShowActivities from './slideshow-activities';
import lang from '../../helpers/translate';

const About = () => {
  return (
    <Container maxWidth='xl'>
      <Stack p={{ xs: 0, md: 3 }} spacing={2}>
        {/* <Stack sx={{ flex: 1 }}>
        <Paper
          sx={{
            width: '100%',
            height: 300,
            backgroundImage: `url(./about.jpg)`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        />
      </Stack> */}
        <SlideShowActivities />

        <Typography variant='h5' sx={{ fontWeight: 700 }}>
          {lang('about_title')}
        </Typography>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ flex: 1.5 }}>
          <Stack direction='column' spacing={2} sx={{ flex: 1 }}>
            <Typography>{lang('about_subtitle_1')}</Typography>
            <Typography>{lang('about_subtitle_2')}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};

export default About;
