import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import './slideshow-activities.css';
import { Stack, Tooltip } from '@mui/material';

const SlideShowActivities = ({ matchSm, data: beritas }) => {
  return (
    <Slide duration={4000} slidesToScroll={matchSm ? 4 : 1} slidesToShow={matchSm ? 4 : 1} indicators={true}>
      {beritas.map((berita) => (
        <Stack key={berita.id} className='each-slide-effect' p={1}>
          <Tooltip title={berita.title}>
            <img
              onClick={() => window.open(berita?.url, '_blank')}
              key={berita.id}
              style={{ width: '100%', height: 300, maxWidth: 400, objectFit: 'cover', objectPosition: 'center', cursor: 'pointer' }}
              src={berita?.thumbnail}
              alt={berita.title}
            />
          </Tooltip>
        </Stack>
      ))}
    </Slide>
  );
};

export default SlideShowActivities;
