// Step 2 & 3: Create React component for bar chart and integrate Highcharts
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';
import { overflow } from '../../../node_modules/@mui/system/esm/display';
import { Divider, Stack, Typography } from '@mui/material';
import { colors } from '@mui/material';
import height from '../../../node_modules/dom-helpers/esm/height';
import lang from '../../helpers/translate';

const IndicatorsInfo = ({ metadata, filter }) => {
  const [data, setData] = React.useState({});

  React.useEffect(() => {
    const _data = _.groupBy(metadata?.metadata, 'category');
    setData(_data);
  }, [metadata]);

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    // return color;
    return colors.teal[400];
  };

  const generateOptions = (filter, metadata) => {
    const getCategories = (item) => {
      const _categories = [];

      item.forEach((part) => {
        if (filter.gender === part?.gender) _categories.push(`${part?.periode} - ${part?.sub_category} (${part?.gender})`);
      });

      return _categories;
    };

    const getValues = (item) => {
      const _values = [];

      item.forEach((part) => {
        if (filter.gender === part?.gender) _values.push(part.percent);
      });

      return _values;
    };

    const getColors = (item) => {
      const _colors = [];

      item.forEach((part) => _colors.push(getRandomColor()));

      return _colors;
    };
    const options = {
      chart: {
        type: 'bar',
        height: '250',
      },
      colors: getColors(metadata),
      scrollbar: {
        enabled: true,
      },
      title: {
        text: filter?.category,
        style: {
          fontSize: '14px',
        },
      },
      xAxis: {
        categories: getCategories(metadata),
        labels: {
          style: {
            fontSize: '9px',
          },
        },
      },
      yAxis: {
        title: {
          text: `${lang('percentage')}(%)`,
        },
      },
      series: [
        {
          name: 'Value',
          data: getValues(metadata),
        },
      ],
    };

    return options;
  };

  return (
    <Stack sx={{ overflowY: 'scroll' }}>
      {!_.isEmpty(data) && !_.isEmpty(filter) ? (
        <Stack direction='column' spacing={2}>
          <Typography variant='h5'>{metadata.desa}</Typography>
          <Divider />
          {/* {Object.keys(data).map((item, index) => (
            <HighchartsReact key={index} highcharts={Highcharts} options={generateOptions(item, data[item])} />
          ))} */}
          <HighchartsReact highcharts={Highcharts} options={generateOptions(filter, data[filter?.category])} />
          {/* {JSON.stringify(filter)} */}
        </Stack>
      ) : (
        <Stack direction='column' spacing={1}>
          <Typography variant='body2'>{lang('select_area')}</Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default IndicatorsInfo;
