import { Container, Stack, Typography } from '@mui/material';
import React from 'react';
import TimelineComponent from '../components/timeline';
import lang from '../helpers/translate';

const Activities = () => {
  const activites = [
    {
      id: '2021',
      year: '2021',
      study: lang('baseline'),
      acts: [
        {
          id: 1,
          label: lang('preparation'),
          image: 'activity-study-preparation.jpg',
        },
        {
          id: 2,
          label: lang('pilot'),
          image: 'activity-pilot-study-2021.jpg',
        },
        {
          id: 3,
          label: lang('stakeholders'),
          image: 'activity-stakeholder-engagement.jpg',
        },
        {
          id: 4,
          label: lang('mapping'),
          image: 'activity-mapping-listing.jpg',
        },
        {
          id: 5,
          label: lang('collection'),
          image: 'activity-data-collection-1.jpg',
        },
      ],
    },
    {
      id: '2022',
      year: '2022',
      study: lang('followup_study'),
      acts: [
        {
          id: 1,
          label: lang('preparation'),
          image: 'activity-study-preparation.jpg',
        },
        {
          id: 2,
          label: lang('collection'),
          image: 'activity-data-collection-4.jpg',
        },
        {
          id: 3,
          label: lang('internal_dissemination'),
        },
      ],
    },
    {
      id: '2024',
      year: '2024',
      study: lang('needs_assessment'),
      acts: [
        {
          id: 1,
          label: lang('learning_action'),
          image: 'pla_1.jpg',
        },
        {
          id: 2,
          label: lang('photovoice'),
        },
        {
          id: 3,
          label: lang('fgd'),
        },
        {
          id: 4,
          label: lang('dissemination'),
        },
      ],
    },
    {
      id: '2025',
      year: '2025',
      study: lang('followup_plan'),
      acts: [],
    },
  ];
  return (
    <Container maxWidth='lg'>
      <Stack p={{ xs: 0, md: 3 }} direction='column' spacing={2}>
        <Typography variant='h5' sx={{ fontWeight: 700, padding: 2 }}>
          {lang('activities')}
        </Typography>
        <TimelineComponent data={activites} />
      </Stack>
    </Container>
  );
};

export default Activities;
