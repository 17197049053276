import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import { Stack, Typography } from '@mui/material';
import ImageCarausel from './image-carausel';

export default function TimelineComponent({ data }) {
  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.2,
        },
      }}
    >
      {data.map((item) => (
        <TimelineItem key={item.id}>
          <TimelineOppositeContent color='textSecondary'>{item.year}</TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color='primary' />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Stack direction='column' spacing={2}>
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={6} sx={{ flex: 1 }}>
                <Stack direction='column' spacing={2} sx={{ flex: 1 }}>
                  <Typography variant='h7' sx={{ fontWeight: 700 }}>
                    {item.study}
                  </Typography>

                  <Stack direction='column' spacing={1}>
                    {item?.acts?.map((act) => (
                      <Stack direction='column' spacing={1} key={act.id}>
                        <Typography>{act.label}</Typography>
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
                <Stack direction='column' spacing={1} sx={{ flex: 2 }}>
                  <ImageCarausel data={item.acts} />
                </Stack>
              </Stack>
            </Stack>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}
