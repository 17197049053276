import { Hidden, Stack, Typography } from '@mui/material';
import React from 'react';
import lang from '../helpers/translate';

const SupportedBy = () => {
  const collaborators = [
    {
      id: 1,
      name: 'WUR',
      url: 'https://www.wur.nl/en/wageningen-university.htm',
      image: './assets/collaborator/wur.png',
      scale: 2,
    },
    {
      id: 2,
      name: 'CHPM',
      url: 'https://chpm.fk.ugm.ac.id/',
      image: './assets/collaborator/chpm.png',
      scale: 0.9,
    },
    {
      id: 3,
      name: 'Immana',
      url: 'https://www.anh-academy.org/immana',
      image: './assets/collaborator/immana.png',
      scale: 1,
    },
    {
      id: 4,
      name: 'NHF',
      url: 'http://www.neys-vanhoogstraten.nl/',
      image: './assets/collaborator/nhf.png',
      scale: 0.7,
    },
    {
      id: 5,
      name: 'Wageningen fund',
      url: 'https://www.wur.nl/en/benefactors.htm',
      image: './assets/collaborator/wageningen.png',
      scale: 1.5,
    },
    {
      id: 5,
      name: 'Kabupaten Gunung Kidul',
      url: '',
      image: './assets/collaborator/gunkid.png',
      scale: 0.9,
    },
  ];
  return (
    <Stack direction={{ xs: 'column', md: 'row' }} alignItems='center' p={6}>
      <Typography variant='body1'>{lang('supported')}:</Typography>
      <Stack direction='row' spacing={0} flexWrap='nowrap' alignItems='center'>
        {collaborators.map((item, index) => (
          <Stack sx={{ cursor: 'pointer' }} onClick={() => window.open(item.url, '_blank')}>
            <Hidden mdDown>
              <img src={item.image} width={100 * item.scale} />
            </Hidden>

            <Hidden mdUp>
              <img src={item.image} width={60 * item.scale} />
            </Hidden>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default SupportedBy;
