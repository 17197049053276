import { Avatar, Button, Container, Divider, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import _ from 'underscore';
import lang from '../../helpers/translate';

const Publication = () => {
  const publications = [
    {
      title: '',
      image: '/assets/publication/cover_1.png',
      url: 'https://edepot.wur.nl/586155',
      desc: lang('book_1'),
    },
    {
      title: '',
      image: '/assets/publication/cover_7.png',
      url: 'https://inhes.org/pub/PLA_and_Photovoice.pdf',
      desc: lang('book_2'),
    },
    {
      title: '',
      image: '/assets/publication/cover_5.png',
      url: '',
      desc: lang('report_1'),
    },
    {
      title: '',
      image: '/assets/publication/cover_4.png',
      url: '',
      desc: lang('report_2'),
    },
    {
      title: '',
      image: '/assets/publication/cover_2.png',
      url: '',
      desc: lang('protocol_1'),
    },
    {
      title: '',
      image: '/assets/publication/cover_3.png',
      url: '',
      desc: lang('protocol_2'),
    },
    {
      title: '',
      image: '/assets/publication/cover_6.png',
      url: '',
      desc: lang('protocol_3'),
    },
  ];

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }
  return (
    <Container maxWidth='lg'>
      <Stack p={{ xs: 0, md: 3 }} direction='column' spacing={2}>
        <Typography variant='h5' sx={{ fontWeight: 700 }}>
          {lang('reports')}
        </Typography>
        <Stack direction='column' spacing={2}>
          <Stack direction='row' flexWrap='wrap' justifyContent={{ xs: 'center', md: 'center' }}>
            {publications.map((publication, index) => (
              <Paper key={index} sx={{ margin: 2, width: 300 }}>
                <Stack direction='column' spacing={2} alignItems='center' p={2}>
                  <img key={index} style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }} src={publication.image} />
                  <Button
                    sx={publication.url ? { color: 'primary', fontWeight: 'bold' } : { color: 'grey' }}
                    onClick={publication?.url ? () => window.open(publication.url, '_blank') : () => alert('Coming soon')}
                  >
                    Download
                  </Button>
                  <Typography variant='caption'>{publication.desc}</Typography>
                </Stack>
              </Paper>
            ))}
          </Stack>
          {/* {Object.keys(groupedTeams).map((key, i) => (
          <Stack direction={{ xs: 'column', sm: 'row' }} key={i} spacing={1}>
            <Stack sx={{ flex: 1 }}>
              <Typography sx={{ textDecoration: 'underline' }}>{groupedTeams[key][0].role}</Typography>
            </Stack>

            <Stack direction='column' spacing={2} sx={{ flex: 3 }}>
              {groupedTeams[key].map((item, index) => (
                <Stack direction='row' spacing={1} alignItems='center'>
                  <Avatar sx={{ bgcolor: stringToColor(item.name) }}>{item.name.substring(0, 2).toUpperCase()}</Avatar>
                  <Typography key={index}>{item.name}</Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        ))} */}
        </Stack>
      </Stack>
    </Container>
  );
};

export default Publication;
