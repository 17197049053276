import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper, Button, Typography, Box, colors, Stack } from '@mui/material';

export default function ImageCarausel({ data }) {
  var items = data.filter((item) => item.image);

  return (
    <Carousel animation='fade' duration={Math.floor(Math.random() * 1000) + 400}>
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
}

function Item(props) {
  return (
    <Paper
      sx={{
        padding: 2,
        height: 200,
        backgroundImage: `url(./${props?.item?.image})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      <Stack direction='column' justifyContent='space-between' sx={{ height: '100%' }}>
        <Typography />
        <Typography variant='body1' sx={{ color: colors.grey[700], backgroundColor: colors.grey[200], padding: 1 }}>
          {props.item.label}
        </Typography>
      </Stack>
    </Paper>
  );
}
