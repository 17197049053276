import React from 'react';
import { Button, Divider, Stack, Typography, colors } from '@mui/material';
import { useLoaderData, useNavigation } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { VolumeOff, VolumeUp } from '@mui/icons-material';
import Cookies from 'js-cookie';

export async function loader() {
  const API = 'https://jsonplaceholder.typicode.com/posts?userId=1';

  try {
    const response = await fetch(API);
    if (!response.ok) throw new Error();

    const data = await response.json();
    return data;
  } catch {
    throw new Response(null, {
      status: 500,
      statusText: 'Internal Server Error',
    });
  } finally {
  }
}

const Home = () => {
  const posts = useLoaderData();
  const navigation = useNavigation();

  const [isMuted, setIsMuted] = React.useState(true);

  const playerRef = React.useRef(null);

  // Function to unmute the video
  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  const videos = {
    idn: 'https://www.youtube.com/watch?v=lRu45LfNd1s ',
    en: 'https://www.youtube.com/watch?v=uDpjL2e1NdE',
  };

  const [selectedVideos, setSelectedVideos] = React.useState('');

  React.useEffect(() => {
    const _lang = Cookies.get('lang');
    let _video = videos.idn;

    if (_lang === 'id') _video = videos.idn;
    if (_lang === 'gb') _video = videos.en;
    setSelectedVideos(_video);
  }, []);

  return (
    <Stack
      // p={10}
      // sx={{ backgroundImage: `url(./assets/webprofile/landingpage-cover.jpg)`, height: 'auto', backgroundPosition: 'top', backgroundSize: 'cover' }}
      alignItems='center'
      justifyContent='center'
    >
      <ReactPlayer ref={playerRef} url={selectedVideos} playing width='100vw' height='100vh' loop={true} muted={isMuted} volume={1} />

      <Stack direction='column' alignItems='center' p={6} spacing={2} sx={{ marginTop: -15 }}>
        {isMuted ? (
          <VolumeOff
            onClick={toggleMute}
            sx={{ cursor: 'pointer', borderRadius: 20, backgroundColor: colors.grey[100], width: 40, height: 40 }}
            color='primary'
          />
        ) : (
          <VolumeUp
            onClick={toggleMute}
            sx={{ cursor: 'pointer', borderRadius: 20, backgroundColor: colors.grey[100], width: 40, height: 40 }}
            color='primary'
          />
        )}
        {/* <Stack direction='row' spacing={3} alignItems='center'>
          <Button onClick={() => setSelectedVideos(videos.idn)} variant='contained'>
            Bahasa Subtitle
          </Button>
          <Button onClick={() => setSelectedVideos(videos.en)} variant='contained'>
            English Subtitle
          </Button>
        </Stack> */}
      </Stack>
    </Stack>
  );
};

export default Home;
