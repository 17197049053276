import { Container, Stack, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import SlideShowActivities from './slideshow-activities';
import lang from '../../helpers/translate';

const Berita = ({ theme }) => {
  const beritas = [
    {
      id: '5',
      thumbnail: './assets/berita/berita_5.png',
      url: 'https://setda.gunungkidulkab.go.id/berita-1082/bincang-pagi-di-nayottama-sinergi-stakeholders-dan-pkmk-ugm-explorasi-strategi-peningkatan-kesehatan-remaja.html',
      title: 'Bincang Pagi di Nayottama: Sinergi Stakeholders dan PKMK UGM Explorasi Strategi Peningkatan Kesehatan Remaja',
    },
    {
      id: '1',
      thumbnail: './assets/berita/berita_1.png',
      url: 'https://desabotodayaan.gunungkidulkab.go.id/first/artikel/2773-PKMK-UGM-Menyapa-Karangwuni-Botodayaan-dalam-Diskusi-Ketahanan-Pangan-dan-Kesehatan-Mental',
      title: lang('news_2'),
    },
    {
      id: '2',
      thumbnail: './assets/berita/berita_2.png',
      url: 'https://desajurangjero.gunungkidulkab.go.id/first/artikel/2013',
      title: lang('news_3'),
    },
    {
      id: '3',
      thumbnail: './assets/berita/berita_3.png',
      url: 'https://desajurangjero.gunungkidulkab.go.id/first/artikel/2012-Menyusuri-Labirin-Pikiran-Remaja--Dialog-PKMK-UGM-dengan-Orang-Tua-tentang-Kesehatan-Mental-Remaja-d',
      title: lang('news_4'),
    },
    {
      id: '4',
      thumbnail: './assets/berita/berita_4.png',
      url: 'https://klaten.pikiran-rakyat.com/kesehatan/pr-2417714008/gali-ketahanan-dan-kesehatan-jiwa-remaja-pkmk-ugm-lakukan-fgd-dengan-puskesmas-dan-balai-penyuluh-kb-nglipar',
      title: lang('news_5'),
    },
    {
      id: '4',
      thumbnail: './assets/berita/berita_6.png',
      url: 'https://dinkes.gunungkidulkab.go.id/bincang-pagi-di-nayottama-sinergi-stakeholders-dan-pkmk-ugm-explorasi-strategi-peningkatan-kesehatan-remaja/',
      title: lang('news_1'),
    },
  ];

  const matchSm = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Container maxWidth='xl'>
      <Stack p={{ xs: 0, md: 3 }} direction='column' spacing={2}>
        <Typography variant='h5' sx={{ fontWeight: 700 }}>
          {lang('news')}
        </Typography>
        <Stack m={2}>
          <SlideShowActivities matchSm={matchSm} data={beritas} />
        </Stack>

        {/* <Stack
        p={20}
        sx={{ backgroundImage: `url(./assets/webprofile/landingpage-cover.jpg)`, height: 'auto', backgroundPosition: 'top', backgroundSize: 'cover' }}
        alignItems='center'
        justifyContent='center'
      ></Stack> */}
      </Stack>
    </Container>
  );
};

export default Berita;
