import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import './slideshow-activities.css';

const SlideShowActivities = () => {
  // const images = [
  //   'https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
  //   'https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80',
  //   'https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
  // ];

  const images = [
    './assets/baseline_2021/training_2.jpg',
    './assets/endline_2022/data_collection_11.jpg',
    './assets/baseline_2021/training_1.jpg',
    './assets/baseline_2021/data_collection_2.jpg',
    './assets/baseline_2021/data_collection_3.jpg',
    './assets/baseline_2021/data_collection_4.jpg',
    './assets/baseline_2021/data_collection_7.jpg',
    './assets/baseline_2021/data_collection_8.jpg',
    './assets/baseline_2021/data_collection_10.jpg',
  ];

  return (
    <Slide duration={4000}>
      {images.map((each, index) => (
        <div key={index} className='each-slide-effect'>
          <img key={index} style={{ width: '100%', height: 500, objectFit: 'cover', objectPosition: 'center' }} src={each} />
        </div>
      ))}
    </Slide>
  );
};

export default SlideShowActivities;
