import { Avatar, Container, Divider, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import _ from 'underscore';
import lang from '../helpers/translate';

const ProjectTeam = () => {
  const teams = [
    {
      role: lang('team_leader'),
      role_id: 'pi',
      name: 'Muhammad Asrullah',
      image: '/assets/foto-peneliti/arul.png',
      url: 'https://www.linkedin.com/in/muhammad-asrullah-0515b2103/',
    },
    {
      role: lang('supervisor'),
      role_id: 'st',
      name: 'Alida Melse-Boonstra',
      image: '/assets/foto-peneliti/alida.png',
      url: 'https://www.linkedin.com/in/alida-melse-a1276822/',
    },

    {
      role: lang('supervisor'),
      role_id: 'st',
      name: 'Monique L’Hoir',
      image: '/assets/foto-peneliti/monique.png',
      url: 'https://www.linkedin.com/in/monique-l-hoir-9726a226b/',
    },
    {
      role: lang('supervisor'),
      role_id: 'st',
      name: 'Edith Feskens',
      image: '/assets/foto-peneliti/edith.png',
      url: 'https://www.linkedin.com/in/edith-feskens-76b90514/',
    },
    {
      role: lang('supervisor'),
      role_id: 'st',
      name: 'Shita Listya Dewi',
      image: '/assets/foto-peneliti/shita.png',
      url: 'https://www.linkedin.com/in/shita-dewi-9b673751/',
    },

    {
      role: lang('supervisor'),
      role_id: 'st',
      name: 'Siti Helmyati',
      image: '/assets/foto-peneliti/siti.png',
      url: 'https://www.linkedin.com/in/siti-helmyati-95425320/',
    },
    {
      role: lang('member'),
      role_id: 'tm',
      name: 'Ahmad Watsiq Maula',
      image: '/assets/foto-peneliti/watsiq.png',
      url: 'https://www.linkedin.com/in/ahmad-watsiq-maula-962409197/',
    },
    {
      role: lang('manager'),
      role_id: 'mn',
      name: 'Yoga Prayanta',
      image: '/assets/foto-peneliti/yoga.png',
      url: 'https://www.linkedin.com/in/yoga-prajanta-717186226/',
    },
  ];

  const groupedTeams = _.groupBy(teams, 'role_id');

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }
  return (
    <Container maxWidth='xl'>
      <Stack p={{ xs: 0, md: 3 }} direction='column' spacing={2}>
        <Typography variant='h5' sx={{ fontWeight: 700, padding: 2 }}>
          {lang('research_team')}
        </Typography>
        <Stack direction='column' spacing={2}>
          <Stack direction={{ xs: 'column', lg: 'row' }} flexWrap='nowrap' justifyContent='center'>
            {teams.map((team, index) => (
              <Paper key={index} sx={{ margin: 1, cursor: 'pointer' }} onClick={() => window.open(team?.url, '_blank')}>
                <Stack direction='column' spacing={1} alignItems='center' p={1}>
                  <img key={index} style={{ width: 120, height: '100%', objectFit: 'cover', objectPosition: 'center' }} src={team.image} />
                  <Typography variant='caption' sx={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => window.open(team?.url, '_blank')}>
                    {team.name}
                  </Typography>
                  <Typography variant='caption'>{team.role}</Typography>
                </Stack>
              </Paper>
            ))}
          </Stack>
          <Stack direction='column' p={4} spacing={2}>
            <Stack direction='row' spacing={2} px={4}>
              <Typography variant='caption' flex={1}>
                {lang('field_team_baseline')}
              </Typography>

              <Typography variant='caption' flex={5}>
                Sandra Olivia Frans, Faisal, Siti Nurfadillah, Hastrin Hositanisita, Yufan Putri, Fuad Wahyu Prabowo, Indra Septian, Diah Setyanto, Puji Astuti,
                Elly Trisnawati, Aini Hidayati, Yuni Laferani, Ratnasari, Muhammad Mustajab, Agil Kukuh B, Agung Suseno, Basuki Sugeng, Syamsul bahri, Awaludin
              </Typography>
            </Stack>
            <Stack direction='row' spacing={2} px={4}>
              <Typography variant='caption' flex={1}>
                {lang('field_team_followup')}
              </Typography>

              <Typography variant='caption' flex={5}>
                Faisal, Fuad Wahyu Prabowo, Diah Setyanto, Aini Hidayati, Yuni Laferani, Ratnasari, Muhammad Mustajab, Agil Kukuh B, Agung Suseno, Awaludin,
                Dawud Rochmatillah
              </Typography>
            </Stack>
            <Stack direction='row' spacing={2} px={4}>
              <Typography variant='caption' flex={1}>
                {lang('field_team_needs')}
              </Typography>

              <Typography variant='caption' flex={5}>
                Nida Adzilah Auliani, Ken Dwiba Amarakamini, Bayu FX Anggara, Agil Kukuh B, Awaludin, Yuni Laferani, Ratnasari, Nopryan Ekadinata (Universitas
                Muhamadiyah Yogyakarta)
              </Typography>
            </Stack>
          </Stack>
          {/* {Object.keys(groupedTeams).map((key, i) => (
          <Stack direction={{ xs: 'column', sm: 'row' }} key={i} spacing={1}>
            <Stack sx={{ flex: 1 }}>
              <Typography sx={{ textDecoration: 'underline' }}>{groupedTeams[key][0].role}</Typography>
            </Stack>

            <Stack direction='column' spacing={2} sx={{ flex: 3 }}>
              {groupedTeams[key].map((item, index) => (
                <Stack direction='row' spacing={1} alignItems='center'>
                  <Avatar sx={{ bgcolor: stringToColor(item.name) }}>{item.name.substring(0, 2).toUpperCase()}</Avatar>
                  <Typography key={index}>{item.name}</Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        ))} */}
        </Stack>
        {/* <Divider /> */}
        {/* <Typography variant='body2'>
          Penelitian ini terselenggara atas Kerjasama antara PKMK UGM, Wageningen University, University Fund Wageningen, dan IMMANA fellowship program
        </Typography> */}
      </Stack>
    </Container>
  );
};

export default ProjectTeam;
